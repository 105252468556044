import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "flex flex-col justify-between items-between w-full h-full"
};
const _hoisted_2 = {
  key: 0
};
import StopView from '@/components/StopView.vue';
import RouteView from '@/components/RouteView.vue';
import DefaultLayout from "@/components/DefaultLayout";
import Sidebar from "@/components/Sidebar.vue";
import Header from '@/components/common/Header.vue';
import { useStopStore } from '@/stores/stopStore'; // Путь к вашему store
import { useRouteStore } from '@/stores/routeStore'; // Путь к вашему stor
import { onMounted, ref, computed } from 'vue';
import { useRoute } from "vue-router";
export default {
  __name: 'RouteStopPage',
  setup(__props) {
    const stopStore = useStopStore();
    const routeStore = useRouteStore();
    const loading = ref(true);
    const error = ref(null);
    const stop = ref({});
    const routeObj = ref({});
    const fetched = ref(false);
    const route = useRoute();
    const pageMode = computed(() => {
      if (route.params.id) {
        return 'update';
      } else return 'create';
    });
    const pageDestination = computed(() => {
      return route.path.split('/')[1];
    });
    const pageTitle = computed(() => {
      if (pageDestination.value === 'stops') {
        if (pageMode.value === 'update') {
          return 'Редактирование остановки';
        } else {
          return 'Создание остановки';
        }
      } else if (pageDestination.value === 'routes') {
        if (pageMode.value === 'update') {
          return 'Редактирование маршрута';
        } else {
          return 'Создание маршрута';
        }
      } else return 'error';
    });
    const loadStop = async () => {
      console.log('LoadStop data!');
      try {
        await stopStore.fetchStop(route.params.id).then(() => {
          stop.value = stopStore.selectedStop;
          console.log('STOPVALUE!!!', stop.value);
        });
      } catch (err) {
        error.value = 'Ошибка при загрузке оастановки';
      } finally {
        console.log('stop data!', stop.value);
        loading.value = false;
        fetched.value = true;
      }
    };
    const loadRoute = async () => {
      console.log('LoadRoute data!');
      try {
        await routeStore.fetchRoute(route.params.id).then(() => {
          routeObj.value = routeStore.selectedRoute;
        });
      } catch (err) {
        error.value = 'Ошибка при загрузке маршрута';
      } finally {
        loading.value = false;
        fetched.value = true;
      }
    };

    // Загружаем мониторы при монтировании компонента
    onMounted(() => {
      if (pageMode.value == 'update') {
        if (pageDestination.value === 'stops') {
          loadStop();
        } else {
          loadRoute();
        }
      } else {
        fetched.value = true;
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(DefaultLayout), null, {
        default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(Header, {
          title: pageTitle.value,
          width: "w-[1180px]"
        }, null, 8, ["title"]), _createElementVNode("div", _hoisted_1, [fetched.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [pageDestination.value === 'stops' ? (_openBlock(), _createBlock(StopView, {
          key: 0,
          data: stop.value,
          mode: pageMode.value
        }, null, 8, ["data", "mode"])) : (_openBlock(), _createBlock(RouteView, {
          key: 1,
          data: routeObj.value,
          mode: pageMode.value
        }, null, 8, ["data", "mode"]))])) : _createCommentVNode("", true)])])]),
        _: 1
      });
    };
  }
};