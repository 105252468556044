import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import './assets/tailwind.css'
import ToastPlugin from 'vue-toast-notification';
import './assets/styles.css'; // Подключение глобального файла стил
import 'vue-toast-notification/dist/theme-bootstrap.css';
const pinia = createPinia();

const app = createApp(App).use(router).use(pinia)

app.use(ElementPlus)
app.use(ToastPlugin);
app.mount('#app')


