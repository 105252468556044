import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "container"
};
import { useRouter } from 'vue-router';
// import { formatDateTime, formatMonitorStatus } from '@/composables/utils';

import { useRouteStore } from '@/stores/routeStore'; // Путь к вашему store
import { useToast } from 'vue-toast-notification';
import DataLoader from './common/DataLoader.vue';
export default {
  __name: 'RouteList',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  emits: ['delete'],
  setup(__props, {
    emit: __emit
  }) {
    const toast = useToast();
    const router = useRouter();
    const emit = __emit; // Объявляем события, которые будут испускаться
    const routeStore = useRouteStore();
    const editItem = item => {
      router.push(`/routes/${item.id}`);
    };
    const deleteItem = async item => {
      await routeStore.deleteRoute(item.id);
      toast.error('Удалено');
      emit('delete');
    };
    const props = __props;
    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_table = _resolveComponent("el-table");
      return _openBlock(), _createBlock(DataLoader, {
        data: __props.data,
        loading: __props.loading
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_table, {
          data: props.data,
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            prop: "name",
            label: "Название"
          }), _createVNode(_component_el_table_column, {
            prop: "stops",
            label: "Остановок в маршруте"
          }, {
            default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.stops.length), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "Действия"
          }, {
            default: _withCtx(scope => [_createElementVNode("div", null, [_createVNode(_component_el_button, {
              type: "primary",
              onClick: $event => editItem(scope.row)
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("Просмотр и редактирование")])),
              _: 2
            }, 1032, ["onClick"]), _createVNode(_component_el_button, {
              type: "danger",
              onClick: $event => deleteItem(scope.row)
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("Удалить")])),
              _: 2
            }, 1032, ["onClick"])])]),
            _: 1
          })]),
          _: 1
        }, 8, ["data"])])]),
        _: 1
      }, 8, ["data", "loading"]);
    };
  }
};