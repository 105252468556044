import "core-js/modules/es.array.push.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "map",
  style: {
    "width": "750px",
    "height": "800px"
  }
};
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import 'maplibre-gl/dist/maplibre-gl.css';
import maplibregl from 'maplibre-gl';

// Определение пропсов

export default {
  __name: 'MapComponent',
  props: {
    center: {
      type: Object,
      default: () => ({
        lat: "58.052403",
        lon: "38.834833"
      })
    },
    points: {
      type: Array,
      default: () => []
    },
    road: {
      type: Array,
      default: () => []
    },
    canCreateRoad: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:coordinates', 'update:road', 'update:point'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;

    // Определение событий
    const emit = __emit;
    const map = ref(null);
    const markers = ref([]); // Храним маркеры в массиве
    const roadCoordinates = ref(props.road.map(point => [point.lon, point.lat]));
    const center = ref(props.center);

    // Инициализация карты
    const initializeMap = () => {
      if (center.value === null) {
        center.value = {
          lat: "58.052403",
          lon: "38.834833"
        };
      }
      console.log('INITIALIZE MAP!!!', props);
      map.value = new maplibregl.Map({
        container: 'map',
        style: {
          version: 8,
          sources: {
            osm: {
              type: 'raster',
              tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
              tileSize: 256,
              attribution: '© OpenStreetMap Contributors',
              maxzoom: 19
            }
          },
          layers: [{
            id: 'osm',
            type: 'raster',
            source: 'osm'
          }]
        },
        center: [center.value.lon, center.value.lat],
        zoom: 13
      });
      map.value.addControl(new maplibregl.NavigationControl());
      map.value.on('load', () => {
        updateRoadLayer(); // Первый раз добавляем линии при загрузке карты
      });

      // Добавляем обработчик клика на карту
      if (props.canCreateRoad) {
        map.value.on('click', e => {
          const newPoint = {
            lon: e.lngLat.lng,
            lat: e.lngLat.lat
          };

          // Обновляем массив координат дороги
          roadCoordinates.value.push([newPoint.lon, newPoint.lat]);
          emit('update:road', roadCoordinates.value); // Уведомляем родительский компонент о новых точках

          // Обновляем линию
          updateRoadLayer();
        });
      }
    };

    // Обновляем слой дороги
    const updateRoadLayer = () => {
      if (map.value.getSource('line')) {
        map.value.getSource('line').setData({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: roadCoordinates.value
          }
        });
      } else {
        map.value.addSource('line', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: roadCoordinates.value
            }
          }
        });
        map.value.addLayer({
          id: 'line',
          type: 'line',
          source: 'line',
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#888',
            'line-width': 4
          }
        });
      }
    };

    // Функция для обновления маркеров
    const updateMarkers = () => {
      markers.value.forEach(marker => marker.remove());
      markers.value = [];
      for (const point of props.points) {
        if (point.coordinates.lat === null || point.coordinates.lon === null) {
          continue;
        }
        const marker = new maplibregl.Marker({
          draggable: point.draggable || false
        }).setLngLat([point.coordinates.lon, point.coordinates.lat]).addTo(map.value);
        marker.on('dragend', () => {
          const lngLat = marker.getLngLat();
          emit('update:point', {
            lon: lngLat.lng,
            lat: lngLat.lat
          });
        });
        markers.value.push(marker);
      }
    };

    // Динамически обновляем центр карты и маркер при изменении координат
    watch(() => props.coordinates, newCoords => {
      if (map.value) {
        map.value.setCenter([newCoords.lon, newCoords.lat]);
        markers.value.forEach(marker => marker.setLngLat([newCoords.lon, newCoords.lat]));
      }
    }, {
      deep: true
    });

    // Слушаем изменения points и road
    watch(() => props.points, () => {
      if (map.value) {
        updateMarkers();
      }
    }, {
      deep: true
    });
    watch(() => props.road, newRoad => {
      roadCoordinates.value = newRoad.map(point => [point.lon, point.lat]);
      if (map.value) {
        updateRoadLayer();
      }
    }, {
      deep: true
    });

    // Жизненные циклы компонента
    onMounted(() => {
      initializeMap();
      updateMarkers(); // Обновляем маркеры сразу после инициализации
    });
    onBeforeUnmount(() => {
      if (map.value) {
        map.value.remove();
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1);
    };
  }
};