import { defineStore } from 'pinia';
import { useFetch } from '@/composables/useFetch';
const fetch = useFetch();


export const useRouteStore = defineStore('routesStore', {
    state: () => ({
        routes: [],
        selectedRoute: {
            name: null,
            stops: [],
            path: [],
        },
        
        pagination: {
            offset: 0,
            limit: 10,
            total: 0,
        },
    }),
    actions: {
        async fetchRoutes(offset = 0, limit = 10) {
            try {
                await fetch.anonRequest(`/routes/?offset=${offset}&limit=${limit}`).then((result) => {
                    this.routes = result.data
                    this.pagination = result.pagination
                });
            } catch (error) {
                console.error('Ошибка при загрузке мониторов:', error);
                // Обработка ошибки (например, уведомление)
            }
        },
        async fetchRoute(id) {
            try {
                this.selectedRoute = await fetch.anonRequest(`/routes/${id}/`); // URL API для
            } catch (error) {
                console.error('Ошибка при загрузке маршрута:', error);
                // Обработка ошибки
            }
        },
        async updateRoute(id, payload) {
            try {
                await fetch.anonRequest(`/routes/${id}/`, {
                    method: 'PATCH',
                    body: payload,
                });
                // Обновление данных после успешного изменения
                await this.fetchRoutes();
            } catch (error) {
                console.error('Ошибка при обновлении маршрута:', error);
                // Обработка ошибки
            }
        },
        async createRoute(payload) {
            try {
                await fetch.anonRequest(`/routes/`, {
                    method: 'POST',
                    body: payload,
                });
                // Обновление данных после успешного изменения
                await this.fetchRoutes();
            } catch (error) {
                console.error('Ошибка при создании маршрута:', error);
                // Обработка ошибки
            }
        },
        async deleteRoute(id) {
            try {
                await fetch.anonRequest(`/routes/${id}/`, {
                    method: 'DELETE',
                });
                // Обновление данных после успешного изменения
                await this.fetchRoutes();
            } catch (error) {
                console.error('Ошибка при удалении маршрута:', error);
                // Обработка ошибки
            }
        },
    },
});