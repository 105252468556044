import { defineStore } from 'pinia';
import { useFetch } from '@/composables/useFetch';
const fetch = useFetch();

export const useMonitorStore = defineStore('monitorStore', {
    state: () => ({
        monitors: [],
        selectedMonitor: {
            name: null,
            id: null,
            coordinates : {
                lat: null,
                lon: null
            },
            external_id: null,
            coordinates_updated_at: null,
            online: null,
            route: null
        },
        
        pagination: {
            offset: 0,
            limit: 10,
            total: 0,
        },
    }),
    actions: {
        async fetchMonitors(offset=0, limit=10) {
            
            try {
                await fetch.anonRequest(`/displays/?offset=${offset}&limit=${limit}`).then((result) => {
                    this.monitors = result.data
                    this.pagination = result.pagination
                    console.log('this monitors', this.monitors)
                });
            } catch (error) {
                console.error('Ошибка при загрузке мониторов:', error);
                // Обработка ошибки (например, уведомление)
            }
        },
        async fetchMonitor(id) {
            try {
                this.selectedMonitor = await fetch.anonRequest(`/displays/${id}/`)
            } catch (error) {
                console.error('Ошибка при загрузке монитора:', error);
                // Обработка ошибки
            }
        },
        async updateMonitor(id, payload) {
            try {
                await fetch.anonRequest(`/displays/${id}/`, {
                    method: 'PATCH',
                    body: payload,
                });
                // Обновление данных после успешного изменения
                await this.fetchMonitors();
            } catch (error) {
                console.error('Ошибка при обновлении монитора:', error);
                // Обработка ошибки
            }
        },
    },
});