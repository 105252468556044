import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import en from 'element-plus/dist/locale/en.js';
import ru from 'element-plus/dist/locale/ru.js';
export default {
  __name: 'App',
  setup(__props) {
    const language = ru;
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_config_provider = _resolveComponent("el-config-provider");
      return _openBlock(), _createBlock(_component_el_config_provider, {
        locale: _unref(language)
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};