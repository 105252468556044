import {createRouter, createWebHistory} from 'vue-router';
import MonitorsPage from '@/views/MonitorsPage.vue';
import MonitorPage from '@/views/MonitorPage.vue';
import RoutesStopsListPage from '@/views/RoutesStopsListPage.vue';
import RouteStopPage from '@/views/RouteStopPage.vue';
const routes = [
    // {
    //     path: '/',
    //     component: IndexPage,
    //     meta: { title: 'Биржа водных вакансий' }
    // },
    {
        path: '/monitors',
        name: 'MonitorsPage',
        component: MonitorsPage,
        meta: { title: 'Страница мониторов' }
    },
    {
        path: '/monitors/:id(\\d+)',
        name: 'UpdateMonitorPage',
        component: MonitorPage,
        meta: { title: 'Редактирование монитора' }
    },
    {
        path: '/stops',
        name: 'StopsPage',
        component: RoutesStopsListPage,
        meta: { title: 'Старница остановок' }
    },
    {
        path: '/routes',
        name: 'Rputes',
        component: RoutesStopsListPage,
        meta: { title: 'Старница маршрутов' }
    },
    {
        path: '/stops/:id(\\d+)',
        name: 'StopPage',
        component: RouteStopPage,
        meta: { title: 'Редактирование остановки' }
    },
    {
        path: '/stops/create',
        name: 'StopPageCreate',
        component: RouteStopPage,
        meta: { title: 'Создание остановки' }
    },
    {
        path: '/routes/:id(\\d+)',
        name: 'RoutePage',
        component: RouteStopPage,
        meta: { title: 'Редактирование маршрута' }
    },
    {
        path: '/routes/create',
        name: 'RoutePageCreate',
        component: RouteStopPage,
        meta: { title: 'Создание маршрута' }
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})


// Устанавливаем заголовок при каждом переходе
router.afterEach((to) => {
    document.title = to.meta.title || 'Умолчание';
});


// router.beforeEach((_to, from) => {
//     const authStore = useAuthStore();
//     authStore.checkIsAuthenticated(); // Вызов метода при смене маршрута
// });

export default router