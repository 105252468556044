export const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const now = new Date();

    const isToday = date.toDateString() === now.toDateString();
    const currentYear = now.getFullYear();
    const options = { hour: '2-digit', minute: '2-digit', hour12: false };

    let formattedTime = date.toLocaleTimeString('ru-RU', options);

    if (isToday) {
        return `Сегодня в ${formattedTime}`;
    } else if (date.getFullYear() === currentYear) {
        const day = date.getDate();
        const month = date.toLocaleString('ru-RU', { month: 'long' });
        return `${day} ${month} в ${formattedTime}`;
    } else {
        const day = date.getDate();
        const month = date.toLocaleString('ru-RU', { month: 'long' });
        const year = date.getFullYear();
        return `${day} ${month} ${year} г. в ${formattedTime}`;
    }
}

export const formatMonitorStatus = (status) => {
    if (status === true) {
        return 'online'
    } else {
        return 'offline'
    }
}