import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "container flex flex-row"
};
const _hoisted_2 = {
  class: "mt-[20px] mr-[30px] w-[400px] flex flex-col justify-between"
};
const _hoisted_3 = {
  class: "flex flex-col gap-y-5"
};
const _hoisted_4 = {
  class: "flex flex-col mb-3"
};
const _hoisted_5 = {
  class: "flex flex-row justify-between"
};
const _hoisted_6 = {
  class: "flex flex-row gap-x-3"
};
const _hoisted_7 = {
  class: "flex flex-row"
};
const _hoisted_8 = {
  class: "mr-3"
};
const _hoisted_9 = {
  key: 0,
  class: "mt-[20px]"
};
import { onMounted, reactive, ref, computed } from 'vue';
import MapComponent from '@/components/MapComponent.vue'; // Импортируем компонент карт
import { useRouteStore } from '@/stores/routeStore'; // Путь к вашему store
import StopSelect from './cards/StopSelect.vue';
import { Close, ArrowDown, ArrowUp } from '@element-plus/icons-vue';
import { useToast } from 'vue-toast-notification';
import router from '@/router';
export default {
  __name: 'RouteView',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: Text,
      default: 'update'
    }
  },
  setup(__props) {
    const toast = useToast();

    // const stopStore = useStopStore();
    const routeStore = useRouteStore();
    const form = reactive({
      name: null,
      id: null,
      stops: [],
      path: []
      // coordinates: {
      //     lat: null,
      //     lon: null
      // },
    });
    const stopExcludeIds = computed(() => {
      return form.stops.map(obj => obj.id);
    });
    const roadCenter = computed(() => {
      if (form.path.length === 0) {
        return null; // Если массив пустой, возвращаем null
      }

      // Находим индекс среднего элемента
      const middleIndex = Math.floor(form.path.length / 2);
      return form.path[middleIndex]; // Возвращаем средний элемент
    });
    // const stops = ref([])

    const loading = ref(true);
    const error = ref(false);
    // const addStopMode = ref(false)

    const handleSelectedItem = item => {
      form.stops.push(item);
    };
    const removeStop = indexToRemove => {
      form.stops.splice(indexToRemove, 1);
    };
    const moveStop = (index, direction) => {
      // Проверяем валидность индекса и направления
      if (index < 0 || index >= form.stops.length) {
        console.log("Индекс вне диапазона.");
        return;
      }
      if (direction === "previous") {
        // Проверяем, что индекс не первый элемент
        if (index === 0) {
          console.log("Нельзя поменять местами первый элемент с предыдущим.");
          return;
        }
        // Меняем местами текущий элемент с предыдущим
        const temp = form.stops[index];
        form.stops[index] = form.stops[index - 1];
        form.stops[index - 1] = temp;
      } else if (direction === "next") {
        // Проверяем, что индекс не последний элемент
        if (index >= form.stops.length - 1) {
          console.log("Нельзя поменять местами последний элемент с следующим.");
          return;
        }
        // Меняем местами текущий элемент с следующим
        const temp = form.stops[index];
        form.stops[index] = form.stops[index + 1];
        form.stops[index + 1] = temp;
      } else {
        console.log("Неизвестное направление. Укажите 'previous' или 'next'.");
        return;
      }
      return;
    };
    const props = __props;
    const handleRoadUpdate = newRoadCoordinates => {
      form.path = newRoadCoordinates.map(item => ({
        lat: item[1],
        lon: item[0]
      }));
      console.log('Обновленная дорога:', form.path);
    };
    const updateRoute = async () => {
      console.log('update route!!');
      try {
        await routeStore.updateRoute(form.id, {
          name: form.name,
          stops: form.stops.map(obj => obj.id)
        }).then(() => {
          console.log('afafafafa');
          toast.success('Сохранено');
          router.push('/routes');
        });
      } catch (err) {
        error.value = 'Ошибка при обновлении остановки';
      } finally {
        loading.value = false;
      }
    };
    const createRoute = async () => {
      console.log('create route!!');
      try {
        await routeStore.createRoute({
          name: form.name,
          stops: form.stops.map(obj => obj.id),
          path: form.path
        }).then(() => {
          console.log('afafafafa');
          toast.success('Сохранено');
          router.push('/routes');
        });
      } catch (err) {
        error.value = 'Ошибка при обновлении остановки';
      } finally {
        loading.value = false;
      }
    };

    // const addStop = () => {
    //     addStopMode.value = true
    // }

    onMounted(() => {
      if (props.mode === 'update') {
        form.name = props.data.name, form.id = props.data.id, form.stops = props.data.stops, form.path = props.data.path;
      }
      console.log('form!!!', form);
      console.log('data2333!!!', props.data);
      loading.value = false;
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_timeline_item = _resolveComponent("el-timeline-item");
      const _component_el_timeline = _resolveComponent("el-timeline");
      const _component_el_button = _resolveComponent("el-button");
      const _component_DataLoader = _resolveComponent("DataLoader");
      return _openBlock(), _createBlock(_component_DataLoader, {
        data: __props.data,
        loading: loading.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[1] || (_cache[1] = _createElementVNode("span", {
          class: "text-left mb-3"
        }, "Название", -1)), _createVNode(_component_el_input, {
          modelValue: form.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.name = $event)
        }, null, 8, ["modelValue"])]), _createVNode(_component_el_timeline, {
          class: "timeline-container",
          style: {
            "max-width": "400px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(form.stops, (item, index) => {
            return _openBlock(), _createBlock(_component_el_timeline_item, {
              key: index,
              color: "green"
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_icon, {
                size: "medium",
                onClick: $event => moveStop(index, 'next')
              }, {
                default: _withCtx(() => [_createVNode(_unref(ArrowDown))]),
                _: 2
              }, 1032, ["onClick"]), _createVNode(_component_el_icon, {
                size: "medium",
                onClick: $event => moveStop(index, 'previous')
              }, {
                default: _withCtx(() => [_createVNode(_unref(ArrowUp))]),
                _: 2
              }, 1032, ["onClick"]), _createVNode(_component_el_icon, {
                size: "medium",
                onClick: $event => removeStop(index)
              }, {
                default: _withCtx(() => [_createVNode(_unref(Close))]),
                _: 2
              }, 1032, ["onClick"])])])]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        }), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(StopSelect, {
          "onUpdate:selectedItem": handleSelectedItem,
          stopExcludeIds: stopExcludeIds.value
        }, null, 8, ["stopExcludeIds"])])])]), props.mode === 'update' ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "primary",
          onClick: updateRoute
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Сохранить")])),
          _: 1
        })) : (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          type: "primary",
          onClick: createRoute
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Создать")])),
          _: 1
        }))]), !loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_9, [props.mode === 'update' ? (_openBlock(), _createBlock(MapComponent, {
          key: 0,
          road: form.path,
          points: form.stops,
          center: roadCenter.value
        }, null, 8, ["road", "points", "center"])) : (_openBlock(), _createBlock(MapComponent, {
          key: 1,
          road: form.path,
          points: form.stops,
          canCreateRoad: true,
          "onUpdate:road": handleRoadUpdate,
          center: roadCenter.value
        }, null, 8, ["road", "points", "center"]))])) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["data", "loading"]);
    };
  }
};