import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "flex flex-row w-full mb-3"
};
const _hoisted_2 = {
  class: "flex flex-row"
};
import { ref } from 'vue';
import { useStopStore } from '@/stores/stopStore';
export default {
  __name: 'StopSelect',
  props: {
    stopExcludeIds: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:selectedItem'],
  setup(__props, {
    emit: __emit
  }) {
    const stopStore = useStopStore();
    const options = ref([]);
    const selectedItem = ref(null); // Хранение выбранного объекта
    const loading = ref(false);
    const emit = __emit;
    const props = __props;
    const addStop = () => {
      console.log('AAAAAAAAA!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', selectedItem.value);
      emit('update:selectedItem', options.value.find(obj => obj.id === selectedItem.value));
      selectedItem.value = null;
    };
    const resetAddStop = () => {
      //  console.log('AAAAAAAAA!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', selectedItem.value)
      //  emit('update:selectedItem', options.value.find(obj => obj.id === selectedItem.value))
      selectedItem.value = null;
    };
    const remoteMethod = query => {
      console.log('zczcfvzfszfz!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', props.stopExcludeIds);
      loading.value = true;
      stopStore.fetchStops(0, 1000, query, props.stopExcludeIds).then(() => {
        options.value = stopStore.stops; // Предполагается массив объектов
        loading.value = false;
      });
    };
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_select, {
        modelValue: selectedItem.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => selectedItem.value = $event),
        filterable: "",
        remote: "",
        placeholder: "Введите название остановки",
        "remote-method": remoteMethod,
        loading: loading.value,
        style: {
          "width": "400px"
        }
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.id,
            label: item.name,
            value: item.id
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "loading"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
        type: "success",
        plain: "",
        onClick: addStop
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("Добавить")])),
        _: 1
      }), _createVNode(_component_el_button, {
        type: "info",
        plain: "",
        onClick: resetAddStop
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Отмена")])),
        _: 1
      })])], 64);
    };
  }
};