import { defineStore } from 'pinia';
import { ofetch } from 'ofetch';
import { useFetch } from '@/composables/useFetch';
const fetch = useFetch();

export const useStopStore = defineStore('stopStore', {
    state: () => ({
        stops: [],
        selectedStop: {
            name: null,
            coordinates : {
                lat: null,
                lon: null
            },
            id: null,
        },
        
        pagination: {
            offset: 0,
            limit: 10,
            total: 0,
        },
    }),
    actions: {
        async fetchStops(offset = 0, limit = 10, query = null, exclude = []) {
            try {
                // Формируем URL
                let url = `/stops/?offset=${offset}&limit=${limit}`;
                
                // Если query заполнен, добавляем его к URL
                if (query) {
                    url += `&name__icontains=${(query)}`;
                }

                if (exclude.length > 0) {
                    url += `&exclude_ids=${exclude}`;
                }
                    
        
                // Делаем запрос
                const result = await fetch.anonRequest(url);
                
                // Обновляем состояние
                this.stops = result.data;
                this.pagination = result.pagination;
                
                console.log('this stops', this.stops);
            } catch (error) {
                console.error('Ошибка при загрузке остановок:', error);
                // Обработка ошибки (например, уведомление)
            }
        },
        async fetchStop(id) {
            try {
                this.selectedStop = await fetch.anonRequest(`/stops/${id}/`); // URL API для
                console.log('selectedStopIS', this.selectedStop)
            } catch (error) {
                console.error('Ошибка при загрузке остановки:', error);
                // Обработка ошибки
            }
        },
        async updateStop(id, payload) {
            try {
                await fetch.anonRequest(`/stops/${id}/`, {
                    method: 'PATCH',
                    body: payload,
                });
                // Обновление данных после успешного изменения
                await this.fetchStops();
            } catch (error) {
                console.error('Ошибка при обновлении остановки:', error);
                // Обработка ошибки
            }
        },
        async createStop(id, payload) {
            try {
                await fetch.anonRequest(`/stops/`, {
                    method: 'POST',
                    body: payload,
                });
                // Обновление данных после успешного изменения
                await this.fetchStops();
            } catch (error) {
                console.error('Ошибка при создании остановки:', error);
                // Обработка ошибки
            }
        },
        async deleteStop(id) {
            try {
                await fetch.anonRequest(`/stops/${id}/`, {
                    method: 'DELETE',
                });
                // Обновление данных после успешного изменения
                await this.fetchStops();
            } catch (error) {
                console.error('Ошибка при удалении остановки:', error);
                // Обработка ошибки
            }
        },
    },
});