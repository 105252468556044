import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-col h-full"
};
const _hoisted_2 = {
  class: "mt-[20px] mb-[20px]"
};
const _hoisted_3 = {
  class: "flex flex-row items-center"
};
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col justify-between items-between w-full h-full"
};
const _hoisted_5 = {
  class: "flex-grow"
};
const _hoisted_6 = {
  class: "mt-[30px]"
};
const _hoisted_7 = {
  key: 1,
  class: "flex flex-col justify-between items-between w-full h-full"
};
const _hoisted_8 = {
  class: "mt-[30px]"
};
import StopList from '@/components/StopList.vue';
import RouteList from '@/components/RouteList.vue';
import DefaultLayout from "@/components/DefaultLayout";
import Sidebar from "@/components/Sidebar.vue";
import Header from "@/components/common/Header.vue";
import { useStopStore } from '@/stores/stopStore'; // Путь к вашему store
import { useRouteStore } from '@/stores/routeStore'; // Путь к вашему store
import { onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
const itemsPerPage = 10;
export default {
  __name: 'RoutesStopsListPage',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const stopStore = useStopStore();
    const routeStore = useRouteStore();
    const stopsLoading = ref(true);
    const routesLoading = ref(true);
    const error = ref(null);
    const stops = ref(null);
    const routes = ref(null);
    const currentPage = ref(1);
    const getDefasultActive = computed(() => {
      return route.path.slice(1);
    });
    const offset = computed(() => {
      return (currentPage.value - 1) * itemsPerPage;
    });
    const pageTitle = computed(() => {
      if (route.path === '/stops') {
        return 'Остановки';
      } else if (route.path === '/routes') {
        return 'Маршруты';
      } else return 'error';
    });
    const createButtonLabel = computed(() => {
      if (route.path === '/stops') {
        return 'Создать остановку';
      } else if (route.path === '/routes') {
        return 'Создать маршрут';
      } else return 'error';
    });
    const createItem = () => {
      router.push(`${route.path}/create`);
      // if (route.path === '/stops') {
      //     router.push('')
      // } else if (route.path === '/routes') {
      //     return 'Создать маршрут'
      // } else return 'error'
    };
    const loadStops = async () => {
      stopsLoading.value = true;
      try {
        await stopStore.fetchStops(offset.value, itemsPerPage).then(() => {
          stops.value = stopStore.stops;
          console.log('stops', stops.value);
        });
      } catch (err) {
        error.value = 'Ошибка при загрузке остановок';
      } finally {
        stopsLoading.value = false;
      }
    };
    const loadRoutes = async () => {
      console.log('load routes!!!');
      routesLoading.value = true;
      try {
        await routeStore.fetchRoutes(offset.value, itemsPerPage).then(() => {
          routes.value = routeStore.routes;
          console.log('routes', routes.value);
        });
      } catch (err) {
        error.value = 'Ошибка при загрузке маршрутов';
      } finally {
        routesLoading.value = false;
      }
    };
    const paginate = async page => {
      console.log('paginate');
      currentPage.value = page;
      if (route.path === '/stops') {
        await loadStops();
      } else {
        await loadRoutes();
      }
    };
    onMounted(() => {
      loadStops();
      loadRoutes();
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_pagination = _resolveComponent("el-pagination");
      return _openBlock(), _createBlock(_unref(DefaultLayout), null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(Header, {
          title: pageTitle.value,
          width: "max-w-[1540px]"
        }, {
          extra: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[0] || (_cache[0] = $event => createItem())
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(createButtonLabel.value), 1)]),
            _: 1
          })])]),
          _: 1
        }, 8, ["title"])]), _unref(route).path === '/stops' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(StopList, {
          data: stops.value,
          onDelete: _cache[1] || (_cache[1] = $event => stops.value = _unref(stopStore).stops),
          loading: stopsLoading.value
        }, null, 8, ["data", "loading"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_pagination, {
          onChange: paginate,
          background: "",
          layout: "prev, pager, next",
          total: _unref(stopStore).pagination.total
        }, null, 8, ["total"])])])) : (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", null, [_createVNode(RouteList, {
          data: routes.value,
          onDelete: _cache[2] || (_cache[2] = $event => routes.value = _unref(routeStore).routes),
          loading: routesLoading.value
        }, null, 8, ["data", "loading"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_el_pagination, {
          onChange: paginate,
          background: "",
          layout: "prev, pager, next",
          total: _unref(routeStore).pagination.total
        }, null, 8, ["total"])])]))])]),
        _: 1
      });
    };
  }
};