import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-col h-full"
};
const _hoisted_2 = {
  class: "mt-[20px] mb-[20px]"
};
const _hoisted_3 = {
  class: "mt-[30px]"
};
import DefaultLayout from "@/components/DefaultLayout";
import Sidebar from "@/components/Sidebar.vue";
import { useMonitorStore } from '@/stores/monitorStore'; // Путь к вашему store
import { onMounted, ref, computed } from 'vue';
import Header from "@/components/common/Header.vue";
// import DataLoader from './common/DataLoader.vue';
const itemsPerPage = 10;
// const totalCount = ref(0)

export default {
  __name: 'MonitorsPage',
  setup(__props) {
    // import MonitorList from '@/components/MonitorList.vue';
    const monitorStore = useMonitorStore();
    const loading = ref(true);
    const error = ref(null);
    const monitors = ref(null);
    const fetched = ref(false);
    const currentPage = ref(1);
    const offset = computed(() => {
      return (currentPage.value - 1) * itemsPerPage;
    });
    const loadMonitors = async () => {
      console.log('LoadMonitors data!');
      try {
        await monitorStore.fetchMonitors(offset.value, itemsPerPage).then(() => {
          monitors.value = monitorStore.monitors;
        });
      } catch (err) {
        error.value = 'Ошибка при загрузке мониторов';
      } finally {
        loading.value = false;
        console.log('monitors data!!', monitors.value);
        fetched.value = true;
      }
    };
    const paginate = async page => {
      currentPage.value = page; // Обновление текущей страницы
      await loadMonitors();
    };
    onMounted(() => {
      loadMonitors();
    });
    return (_ctx, _cache) => {
      const _component_MonitorList = _resolveComponent("MonitorList");
      const _component_el_pagination = _resolveComponent("el-pagination");
      return _openBlock(), _createBlock(_unref(DefaultLayout), null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(Header, {
          title: "Мониторы"
        })]), _createVNode(_component_MonitorList, {
          class: "flex-grow",
          data: monitors.value,
          loading: loading.value
        }, null, 8, ["data", "loading"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_pagination, {
          onChange: paginate,
          background: "",
          layout: "prev, pager, next",
          total: _unref(monitorStore).pagination.total
        }, null, 8, ["total"])])])]),
        _: 1
      });
    };
  }
};