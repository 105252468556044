import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "h4 mr-[30px]"
};
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

// const computedClass = computed(() => {
//     return props.width
// });

export default {
  __name: 'Header',
  props: {
    title: {
      type: String,
      default: 'Название страницы'
    },
    width: {
      type: String,
      default: 'w-full'
    }
  },
  setup(__props) {
    // :title="t('back')"
    const route = useRoute();
    const router = useRouter();
    const props = __props;
    const goBack = () => {
      router.go(-1);
    };
    return (_ctx, _cache) => {
      const _component_el_page_header = _resolveComponent("el-page-header");
      return _openBlock(), _createBlock(_component_el_page_header, {
        onBack: goBack,
        class: _normalizeClass(__props.width)
      }, {
        content: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString(__props.title), 1)]),
        extra: _withCtx(() => [_renderSlot(_ctx.$slots, "extra")]),
        _: 3
      }, 8, ["class"]);
    };
  }
};