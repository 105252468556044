import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useRouter, useRoute } from "vue-router";
import { computed } from "vue";
export default {
  __name: 'Sidebar',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    // Функция-обработчик для выбора пункта меню
    const handleSelect = index => {
      console.log('index', index);
      if (index === "1") {
        router.push('/monitors');
      } else if (index === "2-1") {
        router.push('/stops');
      } else if (index === "2-2") {
        router.push('/routes');
      }
    };
    const getDefasultActive = computed(() => {
      console.log(route.path.split('/')[1]);
      if (route.path.split('/')[1] === 'monitors') {
        return "1";
      } else if (route.path.split('/')[1] === 'stops') {
        return "2";
      } else if (route.path.split('/')[1] === 'routes') {
        return "2";
      } else return "3";
    });
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu = _resolveComponent("el-menu");
      return _openBlock(), _createBlock(_component_el_menu, {
        "default-active": getDefasultActive.value,
        "active-text-color": "#2f056d",
        onSelect: handleSelect,
        class: "h-full"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu_item, {
          index: "1"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon), _cache[0] || (_cache[0] = _createElementVNode("span", {
            class: "text-[20px]"
          }, "Мониторы", -1))]),
          _: 1
        }), _createVNode(_component_el_sub_menu, {
          index: "2",
          open: ""
        }, {
          title: _withCtx(() => [_createVNode(_component_el_icon), _cache[1] || (_cache[1] = _createElementVNode("span", {
            class: "text-[20px]"
          }, "Транспорт", -1))]),
          default: _withCtx(() => [_createVNode(_component_el_menu_item, {
            index: "2-1"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_icon), _cache[2] || (_cache[2] = _createElementVNode("span", {
              class: "text-[20px]"
            }, "Остановки", -1))]),
            _: 1
          }), _createVNode(_component_el_menu_item, {
            index: "2-2"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_icon), _cache[3] || (_cache[3] = _createElementVNode("span", {
              class: "text-[20px]"
            }, "Маршруты", -1))]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["default-active"]);
    };
  }
};