import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "container flex flex-row"
};
const _hoisted_2 = {
  class: "mt-[20px] mr-[30px] w-[400px] flex flex-col justify-between"
};
const _hoisted_3 = {
  class: "flex flex-col gap-y-5"
};
const _hoisted_4 = {
  class: "flex flex-col"
};
const _hoisted_5 = {
  class: "flex flex-col"
};
const _hoisted_6 = {
  class: "flex flex-col"
};
const _hoisted_7 = {
  class: "flex flex-col"
};
const _hoisted_8 = {
  class: "flex flex-row justify-between mb-2"
};
const _hoisted_9 = {
  class: "flex flex-row justify-between mb-1"
};
const _hoisted_10 = {
  class: "flex flex-col"
};
const _hoisted_11 = {
  class: "flex flex-col"
};
const _hoisted_12 = {
  class: "flex flex-col"
};
const _hoisted_13 = {
  key: 0,
  class: "mt-[20px]"
};
import { onMounted, reactive, ref } from 'vue';
import { useRouteStore } from '@/stores/routeStore'; // Путь к вашему store
import MapComponent from '@/components/MapComponent.vue'; // Импортируем компонент карт
import { useMonitorStore } from '@/stores/monitorStore'; // Путь к вашему store
import { formatDateTime, formatMonitorStatus } from '@/composables/utils';
import router from '@/router';
import { useToast } from 'vue-toast-notification';
export default {
  __name: 'MonitorView',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
    // loading: {
    //     type: Boolean,
    //     default: true
    // },
  },
  setup(__props) {
    // import { formatDateTime, formatMonitorStatus } from '@/composables/utils';
    const monitorStore = useMonitorStore();
    const routeStore = useRouteStore();
    const toast = useToast();
    // const coordinates = { lat: 58.0375, long: 38.8504 }
    const form = reactive({
      name: null,
      id: null,
      coordinates: {
        lat: null,
        lon: null
      },
      external_id: null,
      coordinates_updated_at: null,
      online: null,
      route: null
    });
    const error = ref(null);
    const routes = ref([]);
    const loading = ref(true);
    const updateMonitor = async () => {
      try {
        await monitorStore.updateMonitor(form.id, {
          name: form.name,
          route: form.route
        }).then(() => {
          routes.value = routeStore.routes;
          toast.success('Сохранено');
          router.push('/monitors');
        });
      } catch (err) {
        error.value = 'Ошибка при обновлении монитороа';
      } finally {
        loading.value = false;
        console.log('routes data!!', routes.value);
      }
    };
    const props = __props;
    const loadRoutes = async () => {
      try {
        await routeStore.fetchRoutes(0, 1000).then(() => {
          routes.value = routeStore.routes;
        });
      } catch (err) {
        error.value = 'Ошибка при загрузке мониторов';
      } finally {
        console.log('routes data!!', routes.value);
      }
    };
    onMounted(() => {
      loadRoutes();
      console.log('onMounted!!!afasfasfasfasfasfasfasfasfasfas!', props.data);
      if (props.data !== null) {
        form.name = props.data.name, form.id = props.data.id, form.coordinates = props.data.coordinates, form.external_id = props.data.external_id, form.coordinates_updated_at = props.data.coordinates_updated_at, form.online = props.data.online, form.route = props.data.route;
      }
      loading.value = false;
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_DataLoader = _resolveComponent("DataLoader");
      return _openBlock(), _createBlock(_component_DataLoader, {
        data: form.id,
        loading: loading.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[6] || (_cache[6] = _createElementVNode("span", {
          class: "mb-1 text-left"
        }, "Название", -1)), _createVNode(_component_el_input, {
          modelValue: form.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.name = $event)
        }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_5, [_cache[7] || (_cache[7] = _createElementVNode("span", {
          class: "mb-1 text-left"
        }, "ID", -1)), _createVNode(_component_el_input, {
          disabled: "",
          modelValue: form.id,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.id = $event)
        }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_6, [_cache[8] || (_cache[8] = _createElementVNode("span", {
          class: "mb-1 text-left"
        }, "External ID", -1)), _createVNode(_component_el_input, {
          disabled: "",
          modelValue: form.external_id,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form.external_id = $event)
        }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_7, [_cache[11] || (_cache[11] = _createElementVNode("span", {
          class: "mb-1 text-left"
        }, "Координаты", -1)), _createElementVNode("div", _hoisted_8, [_cache[9] || (_cache[9] = _createElementVNode("span", {
          class: "text-left"
        }, "lat", -1)), _createVNode(_component_el_input, {
          class: "w-[80%]",
          disabled: "",
          modelValue: form.coordinates.lat,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form.coordinates.lat = $event)
        }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_9, [_cache[10] || (_cache[10] = _createElementVNode("span", {
          class: "text-left"
        }, "lon", -1)), _createVNode(_component_el_input, {
          class: "w-[80%]",
          disabled: "",
          modelValue: form.coordinates.lon,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => form.coordinates.lon = $event)
        }, null, 8, ["modelValue"])])]), _createElementVNode("div", _hoisted_10, [_cache[12] || (_cache[12] = _createElementVNode("span", {
          class: "mb-1 text-left"
        }, "Координаты обновлены", -1)), _createVNode(_component_el_input, {
          disabled: "",
          value: _unref(formatDateTime)(form.coordinates_updated_at)
        }, null, 8, ["value"])]), _createElementVNode("div", _hoisted_11, [_cache[13] || (_cache[13] = _createElementVNode("span", {
          class: "mb-1 text-left"
        }, "Статус", -1)), _createVNode(_component_el_input, {
          disabled: "",
          value: _unref(formatMonitorStatus)(form.online)
        }, null, 8, ["value"])]), _createElementVNode("div", _hoisted_12, [_cache[14] || (_cache[14] = _createElementVNode("span", {
          class: "mb-1 text-left"
        }, "Маршрут", -1)), _createVNode(_component_el_select, {
          modelValue: form.route,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => form.route = $event),
          placeholder: "Select",
          size: "large",
          style: {
            "width": "240px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(routes.value, item => {
            return _openBlock(), _createBlock(_component_el_option, {
              key: item.id,
              label: item.name,
              value: item.id
            }, null, 8, ["label", "value"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"])])]), _createVNode(_component_el_button, {
          type: "primary",
          onClick: updateMonitor
        }, {
          default: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode("Сохранить")])),
          _: 1
        })]), loading.value === false ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(MapComponent, {
          center: form.coordinates,
          points: [form]
        }, null, 8, ["center", "points"])])) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["data", "loading"]);
    };
  }
};