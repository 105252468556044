import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "flex flex-col justify-between items-between w-full h-full"
};
export default {
  __name: 'DataLoader',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    data: {
      default: null
    },
    create: {
      default: false
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_el_empty = _resolveComponent("el-empty");
      const _directive_loading = _resolveDirective("loading");
      return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [props.loading === false && props.data !== null && props.data.length !== 0 || __props.create === true ? _renderSlot(_ctx.$slots, "default", {
        key: 0
      }) : (_openBlock(), _createBlock(_component_el_empty, {
        key: 1,
        description: "пусто"
      }))])), [[_directive_loading, __props.loading]]);
    };
  }
};