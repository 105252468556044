import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import Header from './common/Header.vue';
import Sidebar from './Sidebar.vue';
export default {
  __name: 'DefaultLayout',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_el_header = _resolveComponent("el-header");
      const _component_el_aside = _resolveComponent("el-aside");
      const _component_el_main = _resolveComponent("el-main");
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createBlock(_component_el_container, {
        class: "h-full"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_header, {
          class: "bg-[#2f056d]"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("div", {
            class: "flex items-center text-white text-left h-full"
          }, [_createElementVNode("div", {
            class: "text-[1.5rem]"
          }, "Трансмедиа")], -1)])),
          _: 1
        }), _createVNode(_component_el_container, null, {
          default: _withCtx(() => [_createVNode(_component_el_aside, {
            class: "w-[15vw]"
          }, {
            default: _withCtx(() => [_createVNode(Sidebar)]),
            _: 1
          }), _createVNode(_component_el_main, {
            class: "h-full"
          }, {
            default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
            _: 3
          })]),
          _: 3
        })]),
        _: 3
      });
    };
  }
};