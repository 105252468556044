import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "container"
};
import { useRouter } from 'vue-router';
import { formatDateTime, formatMonitorStatus } from '@/composables/utils';
import DataLoader from './common/DataLoader.vue';
export default {
  __name: 'MonitorList',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  setup(__props) {
    const router = useRouter();
    const editItem = item => {
      // Логика для редактирования элемента
      // Например, использование vue-router для перехода на страницу редактирования
      router.push(`/monitors/${item.id}`);
    };
    const props = __props;
    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_text = _resolveComponent("el-text");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_table = _resolveComponent("el-table");
      return _openBlock(), _createBlock(DataLoader, {
        data: props.data,
        loading: props.loading
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_table, {
          data: props.data,
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            prop: "name",
            label: "Название"
          }), _createVNode(_component_el_table_column, {
            prop: "status",
            label: "Статус"
          }, {
            default: _withCtx(scope => [scope.row.online ? (_openBlock(), _createBlock(_component_el_text, {
              key: 0,
              type: "success"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("Онлайн")])),
              _: 1
            })) : (_openBlock(), _createBlock(_component_el_text, {
              key: 1,
              type: "danger"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("Офлайн")])),
              _: 1
            }))]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            prop: "coordinates_updated_at",
            label: "Последняя дата связи"
          }, {
            default: _withCtx(scope => [_createTextVNode(_toDisplayString(_unref(formatDateTime)(scope.row.coordinates_updated_at)), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "Действия"
          }, {
            default: _withCtx(scope => [_createVNode(_component_el_button, {
              type: "primary",
              onClick: $event => editItem(scope.row)
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Изменить")])),
              _: 2
            }, 1032, ["onClick"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["data"])])]),
        _: 1
      }, 8, ["data", "loading"]);
    };
  }
};