import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-col justify-between items-between w-full h-full"
};
const _hoisted_2 = {
  key: 0
};
import MonitorView from '@/components/MonitorView.vue';
import DefaultLayout from "@/components/DefaultLayout";
import Sidebar from "@/components/Sidebar.vue";
import Header from '@/components/common/Header.vue';
import { useMonitorStore } from '@/stores/monitorStore'; // Путь к вашему store
import { onMounted, ref } from 'vue';
import { useRoute } from "vue-router";
export default {
  __name: 'MonitorPage',
  setup(__props) {
    const monitorStore = useMonitorStore();
    const loading = ref(true);
    const error = ref(null);
    const monitor = ref(null);
    const route = useRoute();
    // const totalCount = ref(0)

    const loadMonitor = async () => {
      console.log('LoadMonitors data!');
      try {
        await monitorStore.fetchMonitor(route.params.id).then(() => {
          monitor.value = monitorStore.selectedMonitor;
        });
      } catch (err) {
        error.value = 'Ошибка при загрузке мониторов';
      } finally {
        console.log('monitor data!', monitor.value);
        loading.value = false;
      }
    };

    // Загружаем мониторы при монтировании компонента
    onMounted(() => {
      loadMonitor();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(DefaultLayout), null, {
        default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(Header, {
          title: "Редактирование монитора",
          width: "w-[1180px]"
        }), _createElementVNode("div", _hoisted_1, [loading.value !== true ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(MonitorView, {
          data: monitor.value
        }, null, 8, ["data"])])) : _createCommentVNode("", true)])])]),
        _: 1
      });
    };
  }
};